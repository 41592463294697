import _ from 'lodash'

export const getRegLines = (data: any, productLine: string) => {
    const homeRegLine = data.regulatory_text
    const productLineRegLine = _.find(data.navigation_items, {
        codename: productLine?.replace(/-/gi, '_')
    })?.regulatory_text
    if (productLine === 'blog_landing') {
        return {
            home: homeRegLine,
            Pensions: _.find(data.navigation_items, {
                codename: 'pensions'
            })?.regulatory_text,
            'Life Insurance': _.find(data.navigation_items, {
                codename: 'life_insurance'
            })?.regulatory_text,
            Investments: _.find(data.navigation_items, {
                codename: 'investments'
            })?.regulatory_text,
            Health: _.find(data.navigation_items, {
                codename: 'health_insurance'
            })?.regulatory_text
        }
    }
    return productLineRegLine || homeRegLine
}
