import { orderBy } from 'lodash'
import { kentico } from '../../common/services/kentico'
import { getValuesFromCmsArray } from '../../common/utils/getValuesFromCmsArray'
export interface Blog {
    date_created: Date
    lastModified: Date
}

export const getBlogPagesData = (
    blogPosts: Blog[],
    path: string
): any[] | undefined => {
    if (path === '/blog') return blogPosts
    return undefined
}

export const getBlogPosts = async (): Promise<Blog[]> => {
    const blogPosts = getValuesFromCmsArray(await kentico.getBlogPosts()).map(
        (post: Blog) => ({
            ...post,
            date: new Date(post.date_created || post.lastModified)
        })
    )

    return orderBy(blogPosts, 'date', 'desc')
}

export const getLatestBlogPosts = (blogPosts: Blog[]) => {
    return blogPosts.slice(0, 3)
}
