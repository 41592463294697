import { Box } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    Jumbotron,
    JumbotronProps,
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps,
} from 'common/components/organisms/Resources'
import { Sitemap } from 'common/components/organisms/Sitemap'
import { useBrowser } from 'common/hooks/useBrowser'
import { useUrl1 } from 'common/hooks/useUrl1'
import { COOKIEBOT_ID } from 'core'
import React from 'react'
import ScriptTag from 'react-script-tag'
import { FeatureList, FeatureListProps } from '../organisms/FeatureList'
import styled from 'styled-components'

export interface SimpleTextProps {
    benefits: BenefitsProps
    canadaLife: ResourcesProps
    codename: string
    content: string
    dac: ResourcesProps
    arkLife: ResourcesProps
    irishLifeGroup: ResourcesProps
    jumbotron: JumbotronProps
    layout: LayoutProps
    resources: ResourcesProps
    featureList: FeatureListProps
    // ila: ResourcesProps
}

export const SimpleText: React.FC<SimpleTextProps> = ({
    content,
    jumbotron,
    benefits,
    canadaLife,
    dac,
    arkLife,
    irishLifeGroup,
    layout,
    resources,
    featureList,
}) => {
    const page = useUrl1(1)
    const browser = useBrowser()
    const Output = styled.div`
        table {
            border-spacing: 0;
            margin-bottom: 16px;
            margin-top: 16px;
            td {
                height: 80px;
                padding: 16px;
            }
            width: 100%;
        }
        tbody {
            & > :nth-child(1) {
                color: #3a3160;
                font-size: 18px;
            }
            & > :nth-child(n + 2) {
                font-size: 16px;
                color: rgb(118, 111, 144);
            }
            & > :nth-child(even) {
                background: #f0f3fd;
            }
        }
        p,
        li {
            color: #766f90;
            font-size: 16px;
            line-height: 25px;
        }
        h2 {
            color: #3a3160;
            font-size: 24px;
        }
        h3 {
            color: #3a3160;
            font-size: 20px;
        }
        img {
            max-width: 100%;
        }
        // ol {
        //     list-style: none;
        //     counter-reset: my-awesome-counter;
        //     padding-left: 0px;
        // }
        // ol li {
        //     counter-increment: my-awesome-counter;
        // }
        // ol li::marker {
        //     color: #4db7ab;
        //     content: counter(my-awesome-counter) '. ';
        //     font-weight: bold;

        // }
    `
    const getContent = () => {
        switch (page) {
            case 'site-map':
                return <Sitemap />
            case 'first-step-in-financial-planning':
                return (
                    <>
                        <Text html={true} label={content} />
                        <FeatureList {...featureList} />
                    </>
                )
            case 'privacy-notices':
                return (
                    <>
                        <Text html={true} label={content} />
                        <Benefits {...benefits} />
                    </>
                )
            case 'corporate-social-responsibility':
                return (
                    <>
                        <Text html={true} label={content} />
                        <Resources {...resources} />
                        <Spacer />
                    </>
                )
            case 'transfer-annuity-policies':
                return (
                    <>
                        <Text html={true} label={content} />
                        <Resources {...resources} />
                        <Spacer />
                    </>
                )
            case 'solvency-and-financial-condition-reports':
                return (
                    <>
                        <Resources {...resources} />
                        <Spacer />
                        <Resources {...dac} />
                        <Spacer />
                        <Resources {...arkLife} />
                        <Spacer />
                        <Resources {...irishLifeGroup} />
                        <Spacer />
                        <Resources {...canadaLife} />
                    </>
                )
            case 'website-use-policy':
                return (
                    <>
                        <Text label={jumbotron.text} variant='h1' />
                        <Text
                            box={{ color: '#766F90', mt: 7 }}
                            html
                            label={content}
                            variant='subtitle1'
                        />
                        <Box color='#766F90' fontSize={18}>
                            {browser && (
                                <ScriptTag
                                    id='CookieDeclaration'
                                    // isHydrating
                                    src={`https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`}
                                    type='text/javascript'
                                    async
                                ></ScriptTag>
                            )}
                        </Box>
                    </>
                )
            default:
                return (
                    <>
                        <Output>
                            <Text html label={content} />
                        </Output>
                        {resources.title && (
                            <>
                                <Spacer />
                                <Resources {...resources} />
                            </>
                        )}
                        {dac.title && (
                            <>
                                <Spacer />
                                <Resources {...dac} />
                            </>
                        )}
                        {canadaLife.title && (
                            <>
                                <Spacer />
                                <Resources {...canadaLife} />
                            </>
                        )}
                    </>
                )
        }
    }

    return (
        <Box>
            <Layout {...layout}>
                {jumbotron.title && <Jumbotron {...jumbotron} />}
                <Wrapper>{getContent()}</Wrapper>
            </Layout>
        </Box>
    )
}
