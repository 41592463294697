import { ContactProps } from 'common/components/organisms/Contact'
import _ from 'lodash'
import { getContactData } from './getContactData'

export const getContactFormData = (
    data: any,
    productLine: string,
    contactFormFromCMS?: string
): ContactProps | { [index: string]: ContactProps } | null => {
    const contactForms = data.contact_form?.[0].contact_forms
    const contactFormData =
        contactFormFromCMS ||
        _.find(contactForms, {
            codename: `${productLine || 'life_insurance'}_footer`.replace(
                /-/gi,
                '_'
            ),
        })
    if (contactFormData) {
        return getContactData(contactFormData)
    }
    // if (productLine === 'blog_landing') {
    //     return {
    //         home: getContactData(
    //             _.find(contactForms, {
    //                 codename: 'financial_advice',
    //             })
    //         ),
    //         Pensions: getContactData(
    //             _.find(contactForms, {
    //                 codename: 'pensions_footer',
    //             })
    //         ),
    //         'Life Insurance': getContactData(
    //             _.find(contactForms, {
    //                 codename: 'life_insurance_footer',
    //             })
    //         ),
    //         Investments: getContactData(
    //             _.find(contactForms, {
    //                 codename: 'investments_footer',
    //             })
    //         ),
    //         Health: getContactData(
    //             _.find(contactForms, {
    //                 codename: 'health_insurance_footer',
    //             })
    //         ),
    //     }
    // }

    return null
}
