import { ContactProps } from 'common/components/organisms/Contact'
import { FooterProps } from 'common/components/organisms/Footer'
import { getContactFormData } from './getContactFormData'
import { getFooterLinks } from './getFooterLinks'
import { getRegLines } from './getRegLines'

export const getFooterData = (
    siteData: any,
    productLine: string,
    variant: FooterProps['variant'],
    regLineFromCMS?: string,
    contactFormFromCMS?: any
): FooterProps => {
    const regLine = regLineFromCMS || getRegLines(siteData, productLine)
    const contactForm = getContactFormData(
        siteData,
        productLine,
        contactFormFromCMS
    ) as ContactProps
    return {
        contactForm,
        links: getFooterLinks(siteData.footer_links[0]),
        regLine,
        variant,
    }
}
