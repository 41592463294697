export const getNavigation = (siteData: any) => getNavigationLevel1(siteData)

const getNavigationLevel1 = (siteData: any) =>
    siteData.navigation_items.reduce((result: any, productLine: any) => {
        if (!productLine.navigation__navigation_label) return result
        if (
            productLine.navigation__navigation_label ===
            'Sustainability Related Disclosures'
        )
            return result

        if (productLine.type === 'link') {
            result[productLine.codename] = {
                url: productLine.url,
                name: productLine.navigation__navigation_label,
                featured: Boolean(productLine.featured === 'Yes'),
            }
            return result
        }
        const productLineUrl = `/${productLine.codename}`.replace(/_/gi, '-')
        result[productLine.codename] = {
            name: productLine.navigation__navigation_label,
        }

        if (productLine.navigation_items)
            result[productLine.codename].pages = getNavigationLevel2(
                productLine.navigation_items,
                productLineUrl
            )
        else result[productLine.codename].url = productLineUrl
        return result
    }, {})

const getNavigationLevel2 = (navigation: any, productLineUrl: string) => {
    return navigation.reduce((result: any, navigationItem: any) => {
        if (!navigationItem.navigation__navigation_label) return result
        const navigationItemUrl = `${productLineUrl}${
            navigationItem.type.includes('introduction') ||
            navigationItem.type.includes('product_group') ||
            navigationItem.type.includes('campaign_landing')
                ? ''
                : '/' + navigationItem.codename
        }`.replace(/_/gi, '-')
        if (navigationItem.type === 'link') {
            result[navigationItem.codename] = {
                name: navigationItem.navigation__navigation_label,
                url: navigationItem.url,
                featured: Boolean(navigationItem.featured === 'Yes'),
            }
        } else if (navigationItemUrl.startsWith('/your-goals')) {
            result[navigationItem.codename] = {
                name: navigationItem.navigation__navigation_label,
                url: navigationItemUrl.slice(11),
            }
        } else {
            result[navigationItem.codename] = {
                name: navigationItem.navigation__navigation_label,
                url: navigationItemUrl,
            }
        }

        if (
            navigationItem.navigation_items &&
            !['product', 'one_plan_protection', 'product_oneplan'].includes(
                navigationItem.type
            ) &&
            navigationItem.codename !== 'mortgage_protection' &&
            navigationItem.codename !== 'specified_illness_cover'
        )
            result[navigationItem.codename].pages = getNavigationLevel3(
                navigationItem.navigation_items,
                navigationItemUrl
            )
        return result
    }, {})
}
const getNavigationLevel3 = (navigation: any, productLineUrl: string) => {
    return navigation.reduce((result: any, product: any) => {
        if (!product.navigation__navigation_label) return result
        const productUrl = `${productLineUrl}${
            product.type.includes('product_group') ? '' : '/' + product.codename
        }`.replace(/_/gi, '-')

        if (product.type === 'link') {
            result[product.codename] = {
                name: product.navigation__navigation_label,
                url: product.url,
            }
        } else {
            result[product.codename] = {
                name: product.navigation__navigation_label,
                url: productUrl,
            }
        }
        return result
    }, {})
}
