export function getSiteMapData(data: any) {
    const filteredSiteMapPages = data.filter((item: any) => {
        return item.type === 'page'
    })
    let tempLevelParentTag: any = ''
    let currentParentIndex: number = 0
    let tempchildrenArray: any = []
    const tempFirstLevelHierarchicalOrder = filteredSiteMapPages
        .map((item: any, index: number) => {
            if (item.path.split('/').length === 3) {
                currentParentIndex = index
                tempchildrenArray = []
                tempLevelParentTag =
                    item.path.split('/')[item.path.split('/').length - 2]
                item.parentLevelTag =
                    item.path.split('/')[item.path.split('/').length - 2]
                return item
            } else {
                if (item.path.split('/')[1] === tempLevelParentTag) {
                    tempchildrenArray.push(item)
                }
                filteredSiteMapPages[currentParentIndex].childrenPages =
                    tempchildrenArray
            }
            return 0
        })
        .filter(Boolean)

    return tempFirstLevelHierarchicalOrder
}
