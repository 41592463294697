import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface FeatureProps {
    show_bullets: 'Show' | 'Hide'
    text?: string
    title?: string
}

export const Feature: React.FC<FeatureProps> = ({
    text,
    title,
    show_bullets
}) => {
    const noBullets = show_bullets === 'Hide'
    return (
        <Box
            borderLeft={noBullets ? 'none' : '2px solid #50C9B5'}
            mb={noBullets ? 0 : 8}
            pl={noBullets ? 0 : 6}
        >
            {title && (
                <Text
                    box={{ mb: noBullets ? 0 : 3 }}
                    color={!text ? 'textSecondary' : undefined}
                    label={title}
                    style={{ fontSize: '20px' }}
                    component='h2'
                />
            )}
            {text && (
                <Text color='textSecondary' html label={text} component='p' />
            )}
        </Box>
    )
}
