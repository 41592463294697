import React from 'react'
import { JumbotronCallToActionProps } from '../Jumbotron/JumbotronCallToAction'
import { FeatureProps } from './Feature'
import { FeatureListLeft } from './FeatureListLeft'
import { FeatureListRight } from './FeatureListRight'
import { FeatureListTop, FeatureListTopProps } from './FeatureListTop'

export interface FeatureListProps {
    variant?: 'top' | 'left' | 'right'
    features: FeatureProps[]
    subtitle?: string
    image?: string
    scale?: number
    title: string
    callToAction?: JumbotronCallToActionProps
    cta?: string
    text?: string
    featureTitle?: string
    featureSubtitle?: string
    secondaryTitle?: string
}

export const featureListStyles = {
    column: {
        display: 'flex',
        maxHeight: '100%',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}

export const FeatureList: React.FC<FeatureListProps> = ({
    variant = 'right',
    ...props
}) => {
    switch (variant) {
        case 'top':
            return <FeatureListTop {...(props as FeatureListTopProps)} />
        case 'right':
            return <FeatureListRight {...(props as FeatureListProps)} />
        default:
            return <FeatureListLeft {...(props as FeatureListProps)} />
    }
}
