import { kentico } from 'common/services/kentico'
import { Blog } from 'common/utils/getBlogPosts'
import { getValuesFromCmsArray } from 'common/utils/getValuesFromCmsArray'
import { orderBy } from 'lodash'
import { useQuery } from 'react-query'

export const useBlogPosts = () => {
    // Get data from Kentico via react-query
    const cmsQuery = useQuery('blogPosts', () => kentico.getBlogPosts(), {
        refetchOnWindowFocus: false
    })
    if (!cmsQuery.data) return null

    const blogPosts = getValuesFromCmsArray(cmsQuery.data).map(
        (post: Blog) => ({
            ...post,
            date: new Date(post.date_created || post.lastModified)
        })
    )

    return orderBy(blogPosts, 'date', 'desc')
}
