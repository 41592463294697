import _ from 'lodash'
import { kentico } from '../../common/services/kentico'

/**
 * Function taking data received from Kentico and returning plain JSON object
 *
 * @param {*} data
 * @return {*}
 */
export const getValuesFromCmsData = (data: any) => {
    return getValuesFromContentItem(data.item, data.linkedItems)
}

/**
 * Function taking two parameters from Kentico CMS data object.
 * Calling getValueFromContentItemProperty on each property of the contentItem object,
 * returning plain JSON object with values for the website
 *
 * @param {*} contentItem
 * @param {string[]} linkedItems
 * @return {*}
 */
export const getValuesFromContentItem = (
    contentItem: any,
    linkedItems: string[]
) => {
    const contentItemValues = _.reduce(
        contentItem,
        (result: { [key: string]: {} }, property, key) => {
            if (kentico.getInternalProps().indexOf(key) === -1)
                // Add property to the returned object, only if it's not one of the internal props
                // assigned by Kentico (services/kentico/getInternalProps() returns a list of these)
                result[key] = getValueFromContentItemProperty(
                    property,
                    linkedItems
                )
            return {
                ...result,
                codename: contentItem.system.codename,
                lastModified: contentItem.system.lastModified,
                type: contentItem.system.type
            }
        },
        {}
    )

    return contentItemValues
}

/**
 * Function parsing individual property of a content item,
 * stripping the redundant Kentico properties, flattening Kentico object to plain value(s)
 *
 * @param {*} contentItemProperty
 * @param {string[]} linkedItems
 * @return {*}
 */
export const getValueFromContentItemProperty = (
    contentItemProperty: any,
    linkedItems: string[]
) => {
    if (contentItemProperty.linkedItemCodenames?.length) {
        // If the item has linkedItemCodenames property, pull details from linkedItems object
        const linkedItemsValues = getValuesFromLinkedItems(
            contentItemProperty.linkedItemCodenames,
            linkedItems
        )
        return linkedItemsValues
    } else if (contentItemProperty.itemCodenames?.length) {
        // If the item has itemCodenames property, pull details from linkedItems object
        const linkedItemsValues = getValuesFromLinkedItems(
            contentItemProperty.itemCodenames,
            linkedItems,
            contentItemProperty.name
        )
        return linkedItemsValues
    } else if (Array.isArray(contentItemProperty.value)) {
        // If value is an array (assets, links etc) return first elements' url or name only (multiple elements have not been needed)
        return (
            contentItemProperty.value[0]?.url ||
            contentItemProperty.value[0]?.name
        )
    } else if (contentItemProperty.value === '<p><br></p>') return undefined
    else return contentItemProperty.value
}

/**
 * Helper function implementing recurrency.
 * Calls getValuesFromContentItem to enable traversing the tree of website's data structure
 *
 * @param {string[]} linkedItemsIdArray
 * @param {*} linkedItems
 * @return {*}
 */
const getValuesFromLinkedItems = (
    linkedItemsIdArray: string[],
    linkedItems: any,
    propertyName?: string
) => {
    return linkedItemsIdArray.map(linkedItemId => {
        const valuesFromContentItem = getValuesFromContentItem(
            linkedItems[linkedItemId],
            propertyName === 'Related articles' ? [] : linkedItems
        )
        return valuesFromContentItem
    })
}
