type Context = {
    metadata__meta_title: string
    jumbotron__title: string
    jumbotron__subtitle: string
    jumbotron__text: string
    body: string
}

type PagesQueryData = {
    context: Context
    path: string
}[]

export type PageItem = {
    id: string
    type: 'page' | 'content'
    text: string
    path: string
}

export function getSiteSearchData(pages: PagesQueryData) {
    return pages.reduce((acc, { context, path }) => {
        const {
            metadata__meta_title,
            jumbotron__title,
            jumbotron__subtitle,
            jumbotron__text,
            body
        } = context

        const contentMap = {
            jumbotron__title,
            jumbotron__subtitle,
            jumbotron__text,
            body
        }
        const pageMap = { metadata__meta_title }

        Object.entries(contentMap).forEach(([key, text]) => {
            if (key && text) {
                const id = 'content' + key + path
                acc.push({ id, type: 'content', path, text })
            }
        })
        Object.entries(pageMap).forEach(([key, text]) => {
            if (text) {
                const id = 'page' + key + path
                acc.push({ id, type: 'page', path, text })
            }
        })
        return acc
    }, [] as PageItem[])
}
