import { Box, Grid } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { FeatureListProps, featureListStyles } from './FeatureList'
import { FeatureListImage } from './FeatureListImage'
import { Features } from './Features'

export interface FeatureListTopProps extends FeatureListProps {
    subtitle: string
    bulletsTitle: string
}

export const FeatureListTop: React.FC<FeatureListTopProps> = ({
    callToAction,
    features,
    image,
    scale,
    subtitle,
    title,
    bulletsTitle,
    secondaryTitle
}) => {
    const desktop = useDesktop()
    return (
        <Box data-testid='FeatureListTop' component='section'>
            {secondaryTitle && (
                <Text
                box={{ mb: 6, color: '#4DB7AB' }}
                label={secondaryTitle}
                variant='overline'
                component='h1'
            />
            )}
            <Box mx={[0, 0, 24]}>
                <Text
                    box={{
                        mb: 6,
                        textAlign: desktop ? 'center' : undefined
                    }}
                    label={title}
                    variant={desktop ? 'h4' : 'h2'}
                    component='h1'
                />
                <Text
                    box={{ textAlign: desktop ? 'center' : undefined }}
                    color='textSecondary'
                    html
                    label={subtitle}
                    variant='subtitle1'
                    component='p'
                />
            </Box>
            <Grid container>
                <Grid item xs={12} md={5} style={{ order: desktop ? 1 : 2 }}>
                    <Box {...featureListStyles.column}>
                        <Features features={features} title={bulletsTitle} />
                    </Box>
                </Grid>
                <Grid item md={1} style={{ order: 2 }} />
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: 'flex', order: desktop ? 3 : 1 }}
                >
                    <Box {...featureListStyles.column}>
                        {!desktop && <Spacer variant='md' />}
                        <FeatureListImage image={image} />
                    </Box>
                </Grid>
            </Grid>
            <Spacer />
        </Box>
    )
}
