import { FeatureListProps } from 'common/components/organisms/FeatureList'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { SimpleText } from 'common/components/templates/SimpleText'
import { useUrl1 } from 'common/hooks/useUrl1'
import { getLayoutData } from 'common/utils/getLayoutData'
import { getSiteMap } from 'common/utils/getSiteMap'
import React from 'react'

export interface SimpleTextPageProps {
    pageContext: any
}

export const SimpleTextPage: React.FC<SimpleTextPageProps> = ({
    pageContext
}) => {
    const page = useUrl1(1)
    return <SimpleText {...getSimplePageData(pageContext, page)} />
}

export default SimpleTextPage

export const getSimplePageData = (data: any, page: string) => ({
    layout: getLayoutData(data),
    content: data.content,
    jumbotron: getJumbotronData(data),
    // jumbotron:
    //     page === 'website-use-policy'
    //         ? { title: '', text: data.page_title }
    //         : { title: data.page_title, text: '' },
    codename: page,
    featureList: {
        text: data.featurelist__text,
        cta: data.featurelist__cta,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist__image,
        title: data.featurelist__title,
        features: data.featurelist__features,
        variant: data.featurelist2__title
            ? 'right'
            : (undefined as FeatureListProps['variant'])
    },
    benefits: {
        benefits: (data.flatcards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title
    },
    resources: {
        title: data.assurance_title,
        text: data.assurance_text,
        downloads: data.assurance_downloads
    },

    dac: {
        title: data.dac_title,
        text: data.dac_text,
        downloads: data.dac_downloads
    },
    arkLife: {
        title: data.arklife_title,
        text: data.arklife_text,
        downloads: data.arklife_downloads
    },
    irishLifeGroup: {
        title: data.irishlifegroup_title,
        text: data.irishlifegroup_text,
        downloads: data.irishlifegroup_downloads
    },
    canadaLife: {
        title: data.canada_life_title,
        text: data.canada_life_text,
        downloads: data.canada_life_downloads
    },
    sitemap: getSiteMap(data.siteData)
})
