import React from 'react'
import { useSiteData } from 'common/hooks/useSiteData'
import { getPages } from 'common/utils/getPages'
import { getSiteSearchData } from 'common/utils/getSiteSearchData'
import { getSiteMapData } from 'common/utils/getSiteMapData'
import { useBlogPosts } from 'common/hooks/useBlogPosts'
import { Box } from '@chakra-ui/react'

const defineUrl = (url: string) => {
    return `https://www.irishlife.ie/${url}`
}

export const Sitemap: React.FC<any> = () => {
    const siteData: any = useSiteData()
    const blogPosts: any = useBlogPosts()
    if (!siteData || !blogPosts) return null
    const pages = getPages({
        blogPosts,
        navigationItems: siteData.navigation_items,
        siteData,
    })
    const siteSearchData = getSiteSearchData(pages)
    const siteMap = getSiteMapData(siteSearchData)
    const renderedList = siteMap.map((item: any) => {
        if (!item.childrenPages) {
            return (
                <li key={item.id}>
                    <a href={defineUrl(item.path)}>{item.text}</a>
                </li>
            )
        } else {
            return (
                <li key={item.id}>
                    <a href={defineUrl(item.path)}>{item.text}</a>
                    <Box pl={'34px'}>
                        <ul>
                            {item.childrenPages.map((childItem: any) => {
                                return (
                                    <li key={childItem.id}>
                                        <a href={defineUrl(childItem.path)}>
                                            {childItem.text}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </Box>
                </li>
            )
        }
    })

    return (
        <Box>
            <ul>{renderedList}</ul>
        </Box>
    )
}
