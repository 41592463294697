import { DeliveryClient, SortOrder } from '@kentico/kontent-delivery'
import { PREVIEW_MODE } from '../../core'

const deliveryClient = new DeliveryClient({
    projectId: 'ffd8d21b-ebd4-0151-55d5-297335c8f50f',

    previewApiKey:
        'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjNhMTc5YTYzNTMwZjRhMWNhZmY3YzFhNzQ2ZWE1YTJhIiwNCiAgImlhdCI6ICIxNjAwODc3NTM0IiwNCiAgImV4cCI6ICIxOTQ2NDc3NTM0IiwNCiAgInByb2plY3RfaWQiOiAiZmZkOGQyMWJlYmQ0MDE1MTU1ZDUyOTczMzVjOGY1MGYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.U_Y1nUBgQFZE97FH6KTlv4mVpnYqjM4pQNuOffXby9o',

    globalQueryConfig: {
        usePreviewMode: PREVIEW_MODE,
    },
})
export const kentico = {
    getInternalProps: () => ['_config', 'system', '_raw'],
    getItem: async (codeName: string, depthParam: number = 10) =>
        await deliveryClient.item(codeName).depthParameter(depthParam).toPromise(),
    getItemsFeed: async () => await deliveryClient.itemsFeedAll().toPromise(),
    getBlogPosts: async () =>
        await deliveryClient
            .items()
            .type('blog_post')
            .orderParameter('system.last_modified', SortOrder.desc)
            .toPromise(),
    getTemplates: async () =>
        await deliveryClient
            .items()
            .type('template___generic')
            .depthParameter(10)
            .toPromise(),
}
